<ae2-header-widget [config]="headerConfig">
    <ng-template #headerTemplate let-header let-config="config" let-settings="settings">
        <ng-container *ngIf="header.isPreHeaderOpened">
            <ng-container *ngIf="!header.isMobile; else mobilePreHeader">

                <div class="ae2-header-widget-login-or-signup" fxLayout="row" fxLayoutAlign="center start">

                    <ae2-sign-in-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.LOGIN"
                        (onCloseLogin)="header.closeLoginOrSignup()" (onOpenSignup)="header.openSignup()"
                        (onOpenForgot)="header.openForgot()"></ae2-sign-in-top>
                    <ae2-sign-up-top class="pre-header-element ae2-sign-up-top" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.SIGNUP"
                        (onCloseSignup)="header.closeLoginOrSignup()" (onOpenLogin)="header.openLogin()" (onOpenForgot)="header.openForgot()"
                        [templateTop]="signupTopTemplate" [templateBottom]="signupBottomTemplate" [config]="header.signupConfig"></ae2-sign-up-top>
                    <ae2-forgot-password-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT"
                        (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgot)="header.closeLoginOrSignup()"
                        (onNextStepForgot)="header.nextStepForgot()"></ae2-forgot-password-top>
                    <ae2-forgot-password-done-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT_DONE"
                        (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgotDone)="header.closeLoginOrSignup()"
                        (onOpenLoginDone)="header.openLogin()"></ae2-forgot-password-done-top>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #mobilePreHeader>
            <div class="ae2-header-widget-pre-header-mobile">
                <ae2-sign-in-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.LOGIN"
                    (onCloseLogin)="header.closeLoginOrSignup()" (onOpenSignup)="header.openSignup()" (onOpenForgot)="header.openForgot()"></ae2-sign-in-top>
                <ae2-sign-up-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.SIGNUP"
                    (onCloseSignup)="header.closeLoginOrSignup()" (onOpenLogin)="header.openLogin()" (onOpenForgot)="header.openForgot()"
                    [templateTop]="signupTopTemplate" [templateBottom]="signupBottomTemplate" [config]="header.signupConfig"></ae2-sign-up-top>
                <ae2-forgot-password-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT"
                    (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgot)="header.closeLoginOrSignup()"
                    (onNextStepForgot)="header.nextStepForgot()"></ae2-forgot-password-top>
                <ae2-forgot-password-done-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT_DONE"
                    (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgotDone)="header.closeLoginOrSignup()"
                    (onOpenLoginDone)="header.openLogin()"></ae2-forgot-password-done-top>
            </div>
        </ng-template>

        <ae2-header-login-widget [template]="header.headerLoginTemplate" [isLoginInHeader]="config.isLoginInHeader"
            (onOpenLogin)="header.openLogin()" (onOpenSignup)="header.openSignup()">
            <ng-template #headerLoginWidget></ng-template>
        </ae2-header-login-widget>
        <div fxLayout fxLayoutAlign="space-between center" class="header-theme">
            <div class="fullMobile" fxLayout fxLayoutAlign.xs="space-between center" fxLayoutAlign="start center"
                fxLayoutGap="15px">
                <div>
                    <!-- <h1 class="header-theme-brand" routerLink="/"></h1> -->
                    <a href="https://www.meumood.com/"><h1 class="header-theme-brand"></h1></a>
                </div>
            </div>
            <ng-container *ngIf="!header.isMobile">
                <div class="fullHeader">
                    <ae2-header-login-widget [template]="header.headerLoginTemplate" [isLoginInHeader]="config.isLoginInHeader"
                        (onOpenLogin)="header.openLogin()" (onOpenSignup)="header.openSignup()">
                        <ng-template #headerLoginWidget let-login let-isLoginInHeader="isLoginInHeader">
                            <div class="header-theme-nav" fxLayout fxLayoutAlign="end center">
                                <!-- <ng-container *ngIf="login.isLoggedIn; else notLoggedIn">
                                    <ng-container *ngIf="login.authStore$ | async as authStore">

                                        <ng-container *ngIf="authStore.user.isImpersonate; else notImpersonate">
                                            <button class="header-theme-nav-button-menu" mat-button>{{ authStore.user.impersonatorName
                                                }} ({{ authStore.user.firstName }})
                                            </button>
                                        </ng-container>
                                        <ng-template #notImpersonate>
                                            <button class="header-theme-nav-button-menu" mat-button>
                                                Olá, {{ authStore.user.firstName }}!
                                            </button>
                                        </ng-template>
                                        <button class="header-theme-nav-button-menu" mat-button href="#" routerLink="/minha-conta/meus-dados">
                                            <mat-icon>fiber_manual_record</mat-icon>MINHA CONTA
                                        </button>

                                        <ng-container *ngIf="authStore.user.isImpersonate; else exitNoImpersonate">
                                            <button class="header-theme-nav-button-menu" mat-button href="/impersonate/stop/">
                                                SAIR <mat-icon>exit_to_app</mat-icon>
                                            </button>
                                        </ng-container>
                                        <ng-template #exitNoImpersonate>
                                            <button class="header-theme-nav-button-menu" mat-button (click)="login.logOut()">
                                                SAIR <mat-icon>exit_to_app</mat-icon>
                                            </button>
                                        </ng-template>
                                    </ng-container>
                                </ng-container> -->

                                <!-- <ng-template #notLoggedIn>
                                    <ng-container *ngIf="login.isLoginInHeader; else toLoginPage">
                                        <button class="header-theme-nav-button-menu" mat-button (click)="login.openLogin()">
                                            ENTRAR
                                        </button>
                                        <button class="header-theme-nav-button-menu" mat-button fxHide.xs (click)="login.openSignup()">
                                            CADASTRAR
                                        </button>
                                    </ng-container>
                                    <ng-template #toLoginPage>
                                        <button class="header-theme-nav-button-menu" mat-button href="#" routerLink="/login">
                                            ENTRAR
                                        </button>
                                        <button class="header-theme-nav-button-menu" mat-button href="#" routerLink="/login">
                                            CADASTRAR
                                        </button>
                                    </ng-template>
                                </ng-template> -->

                                <div fxLayout>
                                    <div fxLayout>
                                        <ng-container *ngIf="!login.isLoggedIn">
                                            <button class="header-theme-nav-button-menu" mat-button [matMenuTriggerFor]="menuEnter">Conta</button>
                                            <mat-menu #menuEnter="matMenu">
                                                <button class="whiteMenu" mat-menu-item (click)="login.openLogin()">Entrar</button>
                                                <button class="whiteMenu" mat-menu-item (click)="login.openSignup()">Cadastre-se</button>
                                            </mat-menu>
                                        </ng-container>
                                    </div>
                                    <ng-container *ngIf="login.isLoggedIn">
                                        <ng-container *ngIf="login.authStore$ | async as authStore">
                                            <ng-container *ngIf="authStore.user.isImpersonate; else notImpersonate">
                                                <button [matMenuTriggerFor]="menu" class="header-theme-nav-button-menu"
                                                    mat-button>{{ authStore.user.impersonatorName
                                                    }} ({{ authStore.user.firstName }})
                                                </button>
                                            </ng-container>
                                            <ng-template #notImpersonate>
                                                <button [matMenuTriggerFor]="menu" class="header-theme-nav-button-menu"
                                                    mat-button>
                                                    Olá, {{ authStore.user.firstName }}!
                                                </button>
                                            </ng-template>
                                            <mat-menu #menu="matMenu">
                                                <button class="whiteMenu" mat-menu-item routerLink="/minha-conta/meus-dados">Minha
                                                    conta</button>
                                                <ng-container *ngIf="authStore.user.isImpersonate; else exitNoImpersonate">
                                                    <button class="whiteMenu" mat-menu-item href="/impersonate/stop/">Sair</button>
                                                </ng-container>
                                                <ng-template #exitNoImpersonate>
                                                    <button class="whiteMenu" mat-menu-item (click)="login.logOut()">Sair</button>
                                                </ng-template>
                                            </mat-menu>
                                        </ng-container>
                                    </ng-container>
                                    <button class="header-theme-nav-button-menu calendar" mat-button routerLink="/agenda">Agenda</button>
                                    <!-- <button class="header-theme-nav-button-menu" mat-button routerLink="/pacotes">Planos</button> -->
                                    <button class="header-theme-nav-button-menu" mat-button routerLink="/checkout">
                                        <a href="https://www.instagram.com/qualseumood/" target="_blank">
                                            <i class="fa fa-instagram"></i>
                                        </a>
                                    </button>
                                    <!-- <button class="header-theme-nav-button-menu" mat-button routerLink="/checkout">
                                        <mat-icon>shopping_cart</mat-icon>
                                    </button> -->
                                </div>
                            </div>
                        </ng-template>
                    </ae2-header-login-widget>
                </div>
            </ng-container>
        </div>

    </ng-template>

</ae2-header-widget>
<ae2-header-layout-placement-widget></ae2-header-layout-placement-widget>