import { Component, OnInit, Injectable, ChangeDetectorRef } from '@angular/core';
import { TranslationService } from 'angular-l10n';

@Injectable()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showFooter: boolean = true;

  showHeader: boolean = true;

  isOpen: boolean = false;

  headerColor: string = '#2e2e2e';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translationService: TranslationService
  ) {
    // Roda quando a aplicação iniciar, assim a tradução do 
    // core já foi carregada e podemos sobrescrever 
    this.overwriteTranslation();
  }

  ngOnInit(): void {
  }

  openMenu(): void {
    this.isOpen = !this.isOpen;
  }

  changeHeader(color: string): void {
    this.headerColor = color;
    this.changeDetectorRef.detectChanges();
  }

  overwriteTranslation(): void {
    const translation = {
      core: {
        global: {
          messages: {
            events: {
              bookingQuestion: 'Deseja reservar este evento do dia {{ eventDate }} às {{ eventTime }} com instrutor {{ instructorName }}?'
            }
          }
        }
      }
    };

    (<any>this.translationService).addData(translation, 'pt');
  }

}
