<ng-container *ngIf="showHeader">
    <app-header></app-header>
</ng-container>
<div class="page-content">
    <router-outlet></router-outlet>
</div>
<!-- <div class="page-menu" (click)="openMenu()">
    MENU
</div> -->

<ng-container *ngIf="showFooter">
    <app-footer></app-footer>
</ng-container>