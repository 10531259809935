<footer class="footer">
    <section class="footer-bottom" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
        <div>MeuMood © 2018. Todos os direitos reservados.</div>
        <div routerLink="/">
            <div class="footer-content-brand"></div>
        </div>
        <div>
            <a class="link" href="https://angular-ecommerce.com" target="_blank">Powered by Angular e-Commerce</a>
        </div>
    </section>
</footer>