import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Ae2HeaderWidgetConfig } from '@angularecommerce/core/components/header-widget';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() headerColor: string;

  headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.headerConfig.transparentOnRoutes = [];
    this.headerConfig.showForgotPasswordDone = false;
  }

  openMenu(): void {
    this.appComponent.openMenu();
  }

 }
