import { Routes } from '@angular/router';
import { Ae2AuthRouteGuardService } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
export var CALENDAR_RESOLVER_CACHE_KEY = 'calendar-filter-default-activity';
export var CALENDAR_RESOLVER_EVENT_CACHE_KEY = 'calendar-filter-default-activity-event';
var ɵ0 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ1 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ2 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ3 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ4 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ5 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ6 = { someProperty: AE2_ROUTER_CLASSES_DATA };
// @Injectable()
// export class CalendarResolver implements Resolve<any> {
//   constructor(
//     private ae2ActivitiesService: Ae2ActivitiesService
//   ) { }
//   resolve(): Observable<Ae2ActivityModel> {
//     if (localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)) {
//       return of(JSON.parse(localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)));
//     }
//     const query = new Ae2ActivitiesServiceGetAllQuery();
//     query.active = true;
//     query.use_as_filter = true;
//     query.tag = 'movement';
//     return this.ae2ActivitiesService.getAll(query)
//       .getData$()
//       .pipe(
//         map(activities => activities.results[0]),
//         tap(activity => localStorage.setItem(CALENDAR_RESOLVER_CACHE_KEY, JSON.stringify(activity)))
//       );
//   }
// }
// @Injectable()
// export class CalendarEventResolver implements Resolve<any> {
//   constructor(
//     private ae2ActivitiesService: Ae2ActivitiesService
//   ) { }
//   resolve(): Observable<Ae2ActivityModel> {
//     if (localStorage.getItem(CALENDAR_RESOLVER_EVENT_CACHE_KEY)) {
//       return of(JSON.parse(localStorage.getItem(CALENDAR_RESOLVER_EVENT_CACHE_KEY)));
//     }
//     const query = new Ae2ActivitiesServiceGetAllQuery();
//     query.active = true;
//     query.use_as_filter = true;
//     query.tag = 'event';
//     return this.ae2ActivitiesService.getAll(query)
//       .getData$()
//       .pipe(
//         map(activities => activities.results[0]),
//         tap(activity => localStorage.setItem(CALENDAR_RESOLVER_EVENT_CACHE_KEY, JSON.stringify(activity)))
//       );
//   }
// }
var routes = [
    // {
    //   path: '',
    //   component: HomeComponent,
    // },
    {
        path: '',
        loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    },
    // {
    //   path: 'login',
    //   loadChildren: 'app/features/login/login.module#LoginModule',
    //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'minha-conta',
        canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/profile/profile.module#ProfileModule',
        data: ɵ0
    },
    {
        path: 'checkout',
        // canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
        data: ɵ1
    },
    {
        path: 'agenda',
        loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    },
    // {
    //   path: 'agenda-movement',
    //   loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    //   // resolve: {
    //   //   defaultActivity: CalendarResolver
    //   // }
    //   // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    // {
    //   path: 'agenda-mind',
    //   loadChildren: 'app/features/calendar-mind/calendar-mind.module#CalendarMindModule',
    //   // resolve: {
    //   //   defaultActivity: CalendarResolver
    //   // }
    //   // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    // {
    //   path: 'agenda-event',
    //   loadChildren: 'app/features/calendar-event/calendar-event.module#CalendarEventModule',
    //   // resolve: {
    //   //   defaultActivity: CalendarEventResolver
    //   // }
    //   // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
    // },
    {
        path: 'evento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ2
    },
    {
        path: 'atividades/:slugatividade/:slugevento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ3
    },
    {
        path: 'clientes/resetar-senha/:uidb/:token',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ4
    },
    {
        path: 'clientes/resetar-senha',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ5
    },
    {
        path: 'pacotes',
        loadChildren: 'app/features/packages/packages.module#PackagesModule',
        data: ɵ6
    },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
