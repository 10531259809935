import { NgModule } from '@angular/core';
import { Ae2HeaderWidgetModule } from '@angularecommerce/core/components/header-widget';
import { HeaderComponent } from './header.component';
import { CommonModule } from '@angular/common';
import { Ae2SignInTopModule } from '@angularecommerce/core/components/sign-in-top';
import { Ae2SignUpTopModule } from '@angularecommerce/core/components/sign-up-top';
import { Ae2ForgotPasswordTopModule } from '@angularecommerce/core/components/forgot-password-top';
import { Ae2ForgotPasswordDoneTopModule } from '@angularecommerce/core/components/forgot-password-done-top';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    Ae2HeaderWidgetModule,
    Ae2SignInTopModule,
    Ae2SignUpTopModule,
    Ae2ForgotPasswordTopModule,
    Ae2ForgotPasswordDoneTopModule,
    RouterModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    MatMenuModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule { }
