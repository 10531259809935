import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, Resolve } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { CalendarComponent } from './features/calendar/calendar.component';
// import { CalendarModule } from './features/calendar/calendar.module';
// import { map, tap } from 'rxjs/operators';
// import { Observable, of } from 'rxjs';
// import { Ae2ActivityModel } from '@angularecommerce/core/services/activities/activities.model';
import { Ae2ActivitiesService, Ae2ActivitiesServiceGetAllQuery } from '@angularecommerce/core/services/activities';
export const CALENDAR_RESOLVER_CACHE_KEY = 'calendar-filter-default-activity';
export const CALENDAR_RESOLVER_EVENT_CACHE_KEY = 'calendar-filter-default-activity-event';

// @Injectable()
// export class CalendarResolver implements Resolve<any> {

//   constructor(
//     private ae2ActivitiesService: Ae2ActivitiesService
//   ) { }

//   resolve(): Observable<Ae2ActivityModel> {
//     if (localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)) {
//       return of(JSON.parse(localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)));
//     }

//     const query = new Ae2ActivitiesServiceGetAllQuery();
//     query.active = true;
//     query.use_as_filter = true;
//     query.tag = 'movement';

//     return this.ae2ActivitiesService.getAll(query)
//       .getData$()
//       .pipe(
//         map(activities => activities.results[0]),
//         tap(activity => localStorage.setItem(CALENDAR_RESOLVER_CACHE_KEY, JSON.stringify(activity)))
//       );
//   }
// }

// @Injectable()
// export class CalendarEventResolver implements Resolve<any> {

//   constructor(
//     private ae2ActivitiesService: Ae2ActivitiesService
//   ) { }

//   resolve(): Observable<Ae2ActivityModel> {
//     if (localStorage.getItem(CALENDAR_RESOLVER_EVENT_CACHE_KEY)) {
//       return of(JSON.parse(localStorage.getItem(CALENDAR_RESOLVER_EVENT_CACHE_KEY)));
//     }

//     const query = new Ae2ActivitiesServiceGetAllQuery();
//     query.active = true;
//     query.use_as_filter = true;
//     query.tag = 'event';

//     return this.ae2ActivitiesService.getAll(query)
//       .getData$()
//       .pipe(
//         map(activities => activities.results[0]),
//         tap(activity => localStorage.setItem(CALENDAR_RESOLVER_EVENT_CACHE_KEY, JSON.stringify(activity)))
//       );
//   }
// }

const routes: Routes = [
  // {
  //   path: '',
  //   component: HomeComponent,
  // },
  {
    path: '',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    // resolve: {
    //   defaultActivity: CalendarResolver
    // }
    // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  // {
  //   path: 'login',
  //   loadChildren: 'app/features/login/login.module#LoginModule',
  //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'checkout',
    // canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'agenda',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    // resolve: {
    //   defaultActivity: CalendarResolver
    // }
    // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  // {
  //   path: 'agenda-movement',
  //   loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
  //   // resolve: {
  //   //   defaultActivity: CalendarResolver
  //   // }
  //   // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  // {
  //   path: 'agenda-mind',
  //   loadChildren: 'app/features/calendar-mind/calendar-mind.module#CalendarMindModule',
  //   // resolve: {
  //   //   defaultActivity: CalendarResolver
  //   // }
  //   // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  // {
  //   path: 'agenda-event',
  //   loadChildren: 'app/features/calendar-event/calendar-event.module#CalendarEventModule',
  //   // resolve: {
  //   //   defaultActivity: CalendarEventResolver
  //   // }
  //   // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  {
    path: 'evento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/:slugatividade/:slugevento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha/:uidb/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'pacotes',
    loadChildren: 'app/features/packages/packages.module#PackagesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },


  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    Ae2AuthRouteGuardServiceModule,
    // HomeModule,
    // CalendarModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [
    // CalendarResolver,
    // CalendarEventResolver,
    // Ae2ActivitiesService
  ]
})
export class AppRoutingModule { }
